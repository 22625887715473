import React from "react"
import { withUnpublishedPreview } from "gatsby-source-prismic"

import BlogPostTemplate from "../Templates/blogPostTemplate"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import SecondaryBanner from "../containers/SecondaryBanner"
import homeTemplate from "./index"
import { ContentWrapper } from "../containers/alpacked.style"

const NotFoundPage = ({ location }) => {
  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="Page Not Found"
        description="404 - Page Not Found"
      />
      <Layout>
        <ContentWrapper>
          <SecondaryBanner
            title="Wrong Way!"
            subtitle="Ooops… The page you are looking for couldn’t be found."
            buttonText="BRING ME BACK"
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    page: BlogPostTemplate,
    homepage: homeTemplate,
    prismicPage: BlogPostTemplate,
    prismicHomepage: homeTemplate,
  },
})
